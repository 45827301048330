@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Overriden value */
    /* The value below is the original value for light/normal mode */
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.typography {
  color: theme(colors.neutral.950);
  font-size: theme(fontSize.xl);
  line-height: theme(fontSize.xl[1].lineHeight);

  :where(.typography > *) {
    margin-top: theme(spacing.6);
    margin-bottom: theme(spacing.6);
  }

  /* Headings */
  :where(h2) {
    font-weight: theme(fontWeight.semibold);
    font-family: theme(fontFamily.display);
    font-variation-settings: theme(fontFamily.display[1].fontVariationSettings);
    font-size: theme(fontSize.2xl);
    line-height: theme(fontSize.2xl[1].lineHeight);
    margin-top: theme(spacing.16);
  }

  :where(h3) {
    font-weight: theme(fontWeight.semibold);
    font-family: theme(fontFamily.display);
    font-variation-settings: theme(fontFamily.display[1].fontVariationSettings);
    font-size: theme(fontSize.xl);
    line-height: theme(fontSize.xl[1].lineHeight);
    margin-top: theme(spacing.10);
  }

  :where(h2 + h3) {
    margin-top: 0;
  }

  /* Lists */
  :where(ul, ol) {
    padding-left: 1.5rem;
  }

  :where(ul) {
    list-style-type: disc;
  }

  :where(ol) {
    list-style-type: decimal;
  }

  :where(li) {
    padding-left: theme(spacing.3);
    margin-top: theme(spacing.6);
  }

  :where(li)::marker {
    color: theme(colors.neutral.500);
  }

  :where(li > *),
  :where(li li) {
    margin-top: theme(spacing.4);
  }

  :where(ol > li)::marker {
    font-size: theme(fontSize.base);
    font-weight: theme(fontWeight.semibold);
  }

  /* Tables */
  :where(table) {
    width: 100%;
    text-align: left;
    font-size: theme(fontSize.base);
    line-height: theme(fontSize.base[1].lineHeight);
  }

  :where(th) {
    font-weight: theme(fontWeight.semibold);
  }

  :where(thead th) {
    padding-bottom: theme(spacing.6);
    border-bottom: 1px solid theme(colors.neutral.950);
  }

  :where(td) {
    vertical-align: top;
    padding-top: theme(spacing.6);
    padding-bottom: theme(spacing.6);
    border-bottom: 1px solid theme(colors.neutral.950 / 0.1);
  }

  :where(:is(th, td):not(:last-child)) {
    padding-right: theme(spacing.6);
  }

  /* Code blocks */
  :where(pre) {
    display: flex;
    background-color: theme(colors.neutral.950);
    border-radius: theme(borderRadius.4xl);
    overflow-x: auto;
    margin-top: theme(spacing.10);
    margin-bottom: theme(spacing.10);
    margin-left: calc(-1 * theme(spacing.6));
    margin-right: calc(-1 * theme(spacing.6));

    @screen sm {
      margin-left: auto;
      margin-right: auto;
    }
  }

  :where(pre code) {
    flex: none;
    padding: theme(padding.8) theme(padding.6);
    font-size: theme(fontSize.base);
    line-height: theme(lineHeight.8);
    color: theme(colors.white);

    @screen sm {
      padding: theme(spacing.10);
    }
  }

  /* <hr> */
  :where(hr) {
    border-color: theme(colors.neutral.950 / 0.1);
    margin-top: theme(spacing.24);
    margin-bottom: theme(spacing.24);
  }

  /* Inline text */
  :where(a) {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.15em;
    text-decoration-skip-ink: none;
    font-weight: theme(fontWeight.semibold);
  }

  :where(strong) {
    font-weight: theme(fontWeight.semibold);
  }

  :where(code:not(pre code)) {
    font-size: calc(18 / 20 * 1em);
    font-weight: theme(fontWeight.semibold);

    &::before,
    &::after {
      content: '`';
    }
  }

  :where(h2 code, h3 code) {
    font-weight: theme(fontWeight.bold);
  }

  /* Figures */
  :where(figure) {
    margin-top: theme(spacing.32);
    margin-bottom: theme(spacing.32);
  }

  /* Spacing overrides */
  :where(.typography:first-child > :first-child),
  :where(li > :first-child) {
    margin-top: 0 !important;
  }

  :where(.typography:last-child > :last-child),
  :where(li > :last-child) {
    margin-bottom: 0 !important;
  }
}

[vaul-drawer][vaul-drawer-direction='bottom']::after {
  z-index: -99 !important;
}
